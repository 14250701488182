<template>
  <el-card id="lastMonth">
    <header>
      <h1>查询上月客服续费</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>选择月份</span>
            <el-date-picker
              v-model="day"
              type="month"
              placeholder="选择月份"
              size="mini"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >

          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <!-- echarts -->
      <div id="chartColumn" style="height: 64vh; width: 100%"></div>
    </main>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { customerRenewList } from "@/api/customer_renew";
// import { getYMDHMS } from "@/utils/timestamp";
export default {
  data() {
    return {
      role:null,
      day: null,
      chartColumn: null,
      rmoney: null,
      smoney: null,
      parentCount: null,
      parentRenewCount: null,
      operators: null,
      parentNotCount: null,
    };
  },
  created() {
    this.lastMonth();
    this.getList();
    this.role = localStorage.getItem("role");

  },
  methods: {
    getNewArr() {
      if (this.role == '客服') {
        return [
          {
            name: "已处理续费公司个数",
            type: "bar",
            data: this.valueArrc,
          },
          {
            name: "公司总数(待续费+已处理公司个数)",
            type: "bar",
            data: this.valueArrb,
          },
          {
            name: "待续费公司个数",
            type: "bar",
            data: this.valueArrd,
          },
        ]
      } else {
        return [
        {
            name: "实续费金额",
            type: "bar",
            data: this.valueArr,
          },
          {
            name: "次月扣费",
            type: "bar",
            data: this.valueArra,
          },
          {
            name: "公司总数(待续费+已处理公司个数)",
            type: "bar",
            data: this.valueArrb,
          },
          {
            name: "已处理续费公司个数",
            type: "bar",
            data: this.valueArrc,
          },
          {
            name: "待续费公司个数",
            type: "bar",
            data: this.valueArrd,
          },
          
        ]
      }
    },
    // 上月月份
    lastMonth() {
      let nowDate = new Date();

      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth(),
      };
      this.day = date.year + "-" + date.month;
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // echarts
    drawColumnChart() {
      this.chartColumn = echarts.init(document.getElementById("chartColumn"));
      this.chartColumn.setOption({
        title: { text: "续费金额排名" },
        tooltip: {},
        legend: {
          orient: "vertical",
          left: "right",
          data: [
            "实续费金额",
            "次月扣费",
            "公司总数(待续费+已处理公司个数)",
            "已处理续费公司个数",
            "待续费公司个数",
          ],
        },
        xAxis: {
          //   interval: 0, //去除间隙  刻度间隔为0
          data: this.titleArr,
          //   data: [12, 232, 123, 123, 12, 3, 123, 1, 23, 123, 1, 231],
        },
        yAxis: {
          // data: this.valueArr,
        },
        axisLabel: {
          interval: 0,
          formatter: function (value) {
            // debugger;
            var ret = ""; //拼接加\n返回的类目项
            var maxLength = 4; //每项显示文字个数
            var valLength = value.length; //X轴类目项的文字个数
            var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
            if (rowN > 1) {
              //如果类目项的文字大于3,
              for (var i = 0; i < rowN; i++) {
                var temp = ""; //每次截取的字符串
                var start = i * maxLength; //开始截取的位置
                var end = start + maxLength; //结束截取的位置
                //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                temp = value.substring(start, end) + "\n";
                ret += temp; //凭借最终的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        },
        series: this.getNewArr()
      });
    },
    // 获取数据
    getList() {
      this.day = this.getYMDHMS("YYYY-mm", this.day);
      let params = {
        operators: this.operators,
        day: this.day,
      };
      let res = customerRenewList(params);
      res.then((res) => {
        let data = res.data.data;
        let smoney = 0;
        let rmoney = 0;
        let parentCount = 0;
        let parentRenewCount = 0;
        let parentNotCount = 0;
        data.forEach((v, i) => {
          smoney += v.smoney;
          rmoney += v.rmoney;
          parentCount += v.parentCount;
          parentRenewCount += v.parentRenewCount;
          parentNotCount += v.parentNotCount;
        });
        this.smoney = smoney;
        this.rmoney = rmoney;
        this.parentCount = parentCount;
        this.parentRenewCount = parentRenewCount;
        this.parentNotCount = parentNotCount;
        this.titleArr = [];
        this.valueArr = [];
        this.valueArra = [];
        this.valueArrb = [];
        this.valueArrc = [];
        this.valueArrd = [];
        let newArr = data.map((v) => {
          return { title: v.operators, value: v.rmoney };
        });
        let newArra = data.map((v) => {
          return { title: v.operators, value: v.smoney };
        });
        let newArrb = data.map((v) => {
          return { title: v.operators, value: v.parentCount };
        });
        let newArrc = data.map((v) => {
          return { title: v.operators, value: v.parentRenewCount };
        });
        let newArrd = data.map((v) => {
          return { title: v.operators, value: v.parentNotCount };
        });
        // newArr.sort(function (a, b) {
        //   return b.value - a.value;
        // });
        // newArra.sort(function (a, b) {
        //   return b.value - a.value;
        // });
        newArr.forEach((v) => {
          this.titleArr.push(v.title);
          this.valueArr.push(v.value);
        });
        newArra.forEach((v) => {
          this.valueArra.push(v.value);
        });
        newArrb.forEach((v) => {
          this.valueArrb.push(v.value);
        });
        newArrc.forEach((v) => {
          this.valueArrc.push(v.value);
        });
        newArrd.forEach((v) => {
          this.valueArrd.push(v.value);
        });
        this.$nextTick(() => {
          this.drawColumnChart();
        });
      });
    },
    // 搜索
    handleSearch() {
      this.getList();
    },
    // 重置
    reset() {
      this.day = null;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
#lastMonth {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
}
</style>